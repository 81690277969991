<template>
  <div id="app">
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from "./components/Portfolio.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');
* {
  box-sizing: border-box;
}
html {
    width: 100%;
  height: 100%;
}
body {
  background-color: #f9d29d;
  background-image: linear-gradient(315deg, #f9d29d 0%, #ffd8cb 74%);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // font-family: 'Mochiy Pop P One', sans-serif;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
</style>
